import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export const Loading = () => (
  <div className="w-full">
    <div className="mt-20 max-w-lg mx-auto flex justify-center">
      <Loader type="CradleLoader" />
    </div>
  </div>
);
