import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { createHttpLink } from "apollo-link-http";
import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { WebSocketLink } from "apollo-link-ws";
import { split } from "apollo-link";
import { getMainDefinition } from "apollo-utilities";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { KeycloakProvider } from "@react-keycloak/web";
import { Authentication } from "./Authentication";
import {
  ModulesListContainer,
  TaskListContainer,
  AddEditModuleContainer,
  AddEditTaskContainer,
  ProfessionsContainer,
  TestPoolContainer,
  ExportContainer,
  HeaderContainer
} from "./containers/SelectionContainer";
import { ClientList, AddEditClient } from "./components/Clients";
import { PrivateRoute } from "./components/PrivateRoute";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { Loading } from "./components/Loading";
import { EditVariant } from "./components/Variants";
import keycloak from "./keycloak";

const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;
const GRAPHQL_HTTP_PROTOCOL = process.env.REACT_APP_GRAPHQL_HTTP_PROTOCOL;
const GRAPHQL_WS_PROTOCOL = process.env.REACT_APP_GRAPHQL_WS_PROTOCOL;
const wsClient = new SubscriptionClient(
  `${GRAPHQL_WS_PROTOCOL}://${window.location.host}${GRAPHQL_ENDPOINT}`,
  {
    reconnect: true,
    connectionParams: () => {
      const token = Authentication.getToken();
      return {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    },
    connectionCallback: (err, res) => {
      if (err) {
        console.error("Error Connecting to Subscriptions Server", err);
      }
    }
  }
);

const wsLink = new WebSocketLink(wsClient);

const httpLink = createHttpLink({
  uri: `${GRAPHQL_HTTP_PROTOCOL}://${window.location.host}${GRAPHQL_ENDPOINT}`
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = Authentication.getToken();

  if (keycloak.isTokenExpired(60)) {
    keycloak.updateToken(60);
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  httpLink
);

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache()
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  onKeycloakEvent = (event, error) => {
    // If token is expired, log user out
    if (event === "onTokenExpired") {
      keycloak.logout();
    }

    if (event === "onAuthSuccess") {
      this.setState({
        loading: false
      });
    }
  };

  onKeycloakTokens = tokens => {
    // If new tokens arrive, update Auth module and reconnect websocket subscriptions
    Authentication.setToken(tokens.token);
    wsClient.close();
  };

  render() {
    return (
      <KeycloakProvider
        initConfig={{ onLoad: "login-required", promiseType: "native" }}
        keycloak={keycloak}
        onEvent={this.onKeycloakEvent}
        onTokens={this.onKeycloakTokens}
        LoadingComponent={<Loading />}
      >
        <ApolloProvider client={client}>
          <Router>
            <HeaderContainer />
            <ErrorBoundary>
              <div className=" flex w-full min-h-screen bg-gray-200">
                <Switch>
                  <PrivateRoute exact path="/">
                    <div className="p-4 text-gray-800 w-full">
                      <TestPoolContainer />
                    </div>
                  </PrivateRoute>
                  <PrivateRoute path="/clients">
                    <div className="p-4 text-gray-800 w-full">
                      <ClientList />
                    </div>
                  </PrivateRoute>
                  <PrivateRoute path="/client/:clientID?">
                    <AddEditClient />
                  </PrivateRoute>
                  <PrivateRoute path="/professions">
                    <div className="p-4 bg-gray-800 flex-initial">
                      <ProfessionsContainer />
                    </div>
                    <div className="p-4 text-gray-800 flex-1">
                      <ModulesListContainer />
                      <TaskListContainer />
                    </div>
                  </PrivateRoute>
                  <PrivateRoute path="/module/:moduleID?">
                    <AddEditModuleContainer />
                  </PrivateRoute>
                  <PrivateRoute path="/add-edit-task/:taskID?">
                    <AddEditTaskContainer />
                  </PrivateRoute>
                  <PrivateRoute path="/export-history">
                    <ExportContainer />
                  </PrivateRoute>
                  <PrivateRoute path="/variants/:variantID">
                    <div className="p-4 text-gray-800 w-full">
                      <EditVariant />
                    </div>
                  </PrivateRoute>
                </Switch>
              </div>
            </ErrorBoundary>
          </Router>
        </ApolloProvider>
      </KeycloakProvider>
    );
  }
}

export default App;
