import React, { useState, useEffect } from "react";
import {
  useSubscription,
  useMutation,
  useLazyQuery,
} from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Link } from "react-router-dom";
import Rodal from "rodal";
import "rodal/lib/rodal.css";

import { ContentHeadline } from "../ContentHeadline";

const MODULES_SUBSCRIPTION = gql`
  subscription TestModules($variantID: uuid) {
    TestModule(
      where: { test_variant_id: { _eq: $variantID } }
      order_by: { created_at: asc }
    ) {
      test_module_id
      name
      description
      instruction
      weight
    }
  }
`;

const GET_CLIENTS_WITH_YEAR_AND_PROFESSIONS = gql`
  query ClientsAndYearsAndProfessions($yearID: uuid) {
    Client {
      client_id
      name
      Years(where: { year_id: { _eq: $yearID } }) {
        portal_id
        description
        year_id
        Professions {
          name
          profession_id
          TestVariants {
            name
            test_variant_id
          }
        }
      }
    }
  }
`;

const DELETE_MODULE = gql`
  mutation DeleteModule($moduleID: uuid) {
    delete_TestModule(where: { test_module_id: { _eq: $moduleID } }) {
      affected_rows
    }
  }
`;

const SETCOPY = gql`
  mutation SetCopy($source: uuid, $destination: uuid) {
    insert_Copy(
      objects: {
        source_id: $source
        target_id: $destination
        copy_type: TestModule
      }
    ) {
      affected_rows
    }
  }
`;

export const ModulesList = (props) => {
  const variantID =
    props.variant && props.variant.test_variant_id
      ? props.variant.test_variant_id
      : null;

  const { data, loading, error } = useSubscription(MODULES_SUBSCRIPTION, {
    variables: {
      variantID: variantID,
    },
  });

  const [deleteTestModule] = useMutation(DELETE_MODULE);

  const [testModule, setTestModule] = useState(props.testModule);
  const [modalVisible, setModalVisible] = useState(false);
  const [copyModuleID, setCopyModuleID] = useState();
  const [selectedTestVariant, setSelectedTestVariant] = useState();
  const [getAllVariants, { loading: modLoading, data: modData }] = useLazyQuery(
    GET_CLIENTS_WITH_YEAR_AND_PROFESSIONS
  );
  const [copyVariant] = useMutation(SETCOPY);
  useEffect(() => {
    if (
      !selectedTestVariant &&
      modData &&
      modData.Client &&
      modData.Client[0].Years &&
      modData.Client[0].Years.length > 0 &&
      modData.Client[0].Years[0].Professions &&
      modData.Client[0].Years[0].Professions.length > 0 &&
      modData.Client[0].Years[0].Professions[0].TestVariants &&
      modData.Client[0].Years[0].Professions[0].TestVariants.length > 0
    ) {
      setSelectedTestVariant(
        modData.Client[0].Years[0].Professions[0].TestVariants[0]
          .test_variant_id
      );
    }
  }, [modData, selectedTestVariant]);

  useEffect(() => {
    // Set first module as default if nothing is selected
    if (!props.testModule && data && data.TestModule[0]) {
      setTestModule(data.TestModule[0].test_module_id);
      props.selectTestModule(data.TestModule[0]);
    } else if (props.testModule) {
      setTestModule(props.testModule.test_module_id);
    }
  }, [setTestModule, data, props]);

  const selectModule = (e) => {
    const selectedValue = e.target.value;
    const selectedTestModule = data.TestModule.filter((mod) => {
      return mod.test_module_id === selectedValue;
    });

    setTestModule(selectedValue);
    props.selectTestModule(selectedTestModule[0]);
  };

  if (!variantID)
    return (
      <div className="flex items-center justify-center min-h-full">
        <p className="text-2xl text-gray-500">
          Bitte eine Variante auf der linken Seite wählen.
        </p>
      </div>
    );

  const selectCopyTestVariant = (e) => {
    setSelectedTestVariant(e.target.value);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :( {error.Error}</p>;
  if (!data) return <p>No data...</p>;
  if (data && data.TestModule && data.TestModule.length === 0) {
    props.selectTestModule(null);
    return (
      <div className="mb-8">
        <ContentHeadline
          title={`Aufgaben bearbeiten für Variante ${props.variant.name}`}
        ></ContentHeadline>
        <Link
          className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center"
          to="/module"
        >
          <svg
            className="fill-current w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
          </svg>
          Neues Modul anlegen
        </Link>
      </div>
    );
  }

  // Select box options of the test modules
  const modulesList = data.TestModule.map((mod) => (
    <option key={mod.test_module_id} value={mod.test_module_id}>
      {mod.name}
    </option>
  ));
  const modList = modData
    ? modData.Client.map((c) => (
        <React.Fragment key={c.client_id}>
          {c.Years.map((year) => (
            <React.Fragment key={year.year_id}>
              {year.Professions.map((prof) => (
                <React.Fragment key={prof.profession_id}>
                  {prof.TestVariants.map((variant) => (
                    <option
                      key={variant.test_variant_id}
                      value={variant.test_variant_id}
                    >
                      {prof.name + " - " + variant.name}
                    </option>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))
    : null;

  const copyCurrentModule = (e) => {
    setCopyModuleID(props.testModule.test_module_id);
    getAllVariants({ variables: { yearID: props.year.year_id } });
    setModalVisible(true);
  };

  const copyModuleAction = () => {
    copyVariant({
      variables: { source: copyModuleID, destination: selectedTestVariant },
    });
    closeModal();
  };

  const closeModal = () => {
    setModalVisible(false);
    setCopyModuleID(undefined);
    setSelectedTestVariant(undefined);
  };

  const deleteCurrentModule = (e) => {
    const really = window.confirm(
      `Willst du das Modul "${props.testModule.name}" wirklich löschen?`
    );
    if (really) {
      // Remove selected module and set the first module as new module
      deleteTestModule({
        variables: {
          moduleID: props.testModule.test_module_id,
        },
      });
      props.selectTestModule(data.TestModule[0]);
    }
  };

  return (
    <>
      <div className="mb-8">
        <ContentHeadline
          title={`Aufgaben bearbeiten für Variante ${props.variant.name}`}
        ></ContentHeadline>
        <div className="flex">
          <div className="relative mr-2">
            <select
              onChange={selectModule}
              value={testModule}
              // defaultValue={testModule}
              className="block appearance-none w-full bg-white border border-white py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              {modulesList}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          <Link
            className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center"
            to="/module"
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
            </svg>
            Neues Modul anlegen
          </Link>
          <Link
            className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center"
            to={`/module/${testModule}`}
          >
            <svg
              className="fill-current w-4 h-4 mr-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
            </svg>
            Modul bearbeiten
          </Link>
          <button
            className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center"
            type="button"
            onClick={copyCurrentModule}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="fill-current w-4 h-4 mr-2"
              viewBox="0 0 24 24"
            >
              <path d="M20,2H10C8.897,2,8,2.897,8,4v4H4c-1.103,0-2,0.897-2,2v10c0,1.103,0.897,2,2,2h10c1.103,0,2-0.897,2-2v-4h4 c1.103,0,2-0.897,2-2V4C22,2.897,21.103,2,20,2z M4,20V10h10l0.002,10H4z M20,14h-4v-4c0-1.103-0.897-2-2-2h-4V4h10V14z" />
            </svg>
            Modul kopieren
          </button>
          <button
            className="ml-4 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent inline-flex items-center"
            type="button"
            onClick={deleteCurrentModule}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              className="fill-current w-4 h-4 mr-2"
            >
              <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
            </svg>
            Modul löschen
          </button>
        </div>
        <hr className="my-4 border-gray-500" />
      </div>
      <Rodal visible={modalVisible} onClose={closeModal}>
        {modLoading && <div>Loading...</div>}
        {!modLoading && (
          <div className="flex flex-col">
            <div className="font-bold">Modul kopieren</div>
            <div className="h-4"></div>
            <div>
              <p className="text-xl">Variante wählen:</p>
            </div>
            <div className="relative mr-2">
              <select
                onChange={selectCopyTestVariant}
                value={selectedTestVariant}
                // defaultValue={testModule}
                className="block appearance-none w-full bg-white border border-white py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                {modList}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            <div className="h-5"></div>
            <div className="flex flex-1 flex-row-reverse items-end">
              <button
                className="ml-4 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent inline-flex items-center"
                type="button"
                onClick={closeModal}
              >
                Abbrechen
              </button>
              <div className="w-1" />
              <button
                className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center"
                type="button"
                onClick={copyModuleAction}
              >
                Starten
              </button>
            </div>
          </div>
        )}
      </Rodal>
    </>
  );
};
