import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Redirect, useParams, Link } from "react-router-dom";
import { FormInput } from "../FormInput";
import { ContentHeadline } from "../ContentHeadline";

const UPDATE_VARIANT = gql`
  mutation UpdateVariant($variantID: uuid, $variant: TestVariant_set_input!) {
    update_TestVariant(
      _set: $variant
      where: { test_variant_id: { _eq: $variantID } }
    ) {
      affected_rows
    }
  }
`;

const GET_VARIANT_BY_ID = gql`
  query VariantByID($variantID: uuid!) {
    TestVariant_by_pk(test_variant_id: $variantID) {
      test_variant_id
      name
      time_in_seconds
      instruction
    }
  }
`;

export const EditVariant = props => {
  const params = useParams();
  let variantID = null;
  if (params && params.variantID) {
    variantID = params.variantID;
  }

  const { loading, error, data } = useQuery(GET_VARIANT_BY_ID, {
    variables: {
      variantID
    },
    fetchPolicy: "no-cache"
  });

  const [editVariant] = useMutation(UPDATE_VARIANT);

  const blankVariant = {
    test_variant_id: null,
    name: "",
    time_in_seconds: 0,
    instruction: ""
  };

  const [variantState, setVariantState] = useState([{ ...blankVariant }]);
  const [redirectState, setRedirectState] = useState(false);

  if (loading) return <p>Loading...</p>;
  if (error) console.error(error);
  if (data && data.TestVariant_by_pk) {
    if (!variantState.test_variant_id) {
      setVariantState(data.TestVariant_by_pk);
    }
  }

  const handleVariantChange = e => {
    setVariantState({
      ...variantState,
      [e.target.name]: e.target.value
    });
  };

  const resetVariant = () => {
    setVariantState(blankVariant);
  };

  const storeVariant = e => {
    e.preventDefault();
    editVariant({
      variables: {
        variantID: variantState.test_variant_id,
        variant: {
          time_in_seconds: variantState.time_in_seconds,
          instruction: variantState.instruction
        }
      }
    }).then(() => {
      resetVariant();
      setRedirectState(true);
    });
  };

  if (redirectState) {
    return <Redirect to="/professions" />;
  }

  return (
    <div className="container mx-auto pt-8">
      <form
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={storeVariant}
      >
        <div className="w-full px-3">
          <ContentHeadline
            title={`Variante ${variantState.name} bearbeiten`}
          ></ContentHeadline>
          <div className="mb-8">
            <FormInput
              label="Bearbeitungszeit (in Sekunden)"
              inputType="number"
              id="time_in_seconds"
              value={variantState.time_in_seconds}
              onChangeEvent={handleVariantChange}
              placeholder="Bitte die Zeit eintragen, die der Tester Zeit hat, um den Test zu lösen"
            />
          </div>
          <div className="mb-8">
            <FormInput
              label="Instruktionen"
              inputType="text"
              id="instruction"
              value={variantState.instruction}
              onChangeEvent={handleVariantChange}
              placeholder="Tragen Sie hier einen Text ein, wenn dem Tester Instruktionen angeueigt werden sollen"
            />
          </div>
          <div className="flex justify-end">
            <Link
              className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center mr-4"
              to="/professions"
            >
              Abbrechen
            </Link>
            <button
              className="bg-purple-500 hover:bg-purple-700 text-white py-2 px-4"
              type="submit"
            >
              {"Variante bearbeiten"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
