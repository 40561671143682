import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

// GraphQL Query for TaskTypes. Its an enum typ in Graphql.
const ORDER_TYPES = gql`
  query OrderTypes {
    OrderType {
      description
      name
    }
  }
`;

export const OrderTypeDopdown = (props) => {
  const currentType = props.currentType;
  // Loading task types from GraphQL API
  const [orderType, setOrderType] = useState(undefined);
  const [orderTypeList, setOrderTypeList] = useState(undefined);
  const { data, loading, error } = useQuery(ORDER_TYPES);

  useEffect(() => {
    if (currentType && orderType !== currentType) {
      setOrderType(currentType);
    }
  }, [currentType, orderType]);

  useEffect(() => {
    if (data && data.OrderType && data.OrderType.length > 0) {
      if (data.OrderType[0].name !== "") {
        const emptyOrderType = { name: "", description: "Bitte wählen" };
        data.OrderType.unshift(emptyOrderType);
      }
      setOrderTypeList(data.OrderType);
    }
  }, [data]);

  // Check if data received, otherwise show some info.
  if (error) return <p>Error :(</p>;
  if (loading) return <p>Loading...</p>;
  if (
    !data ||
    !data.OrderType ||
    (data && data.OrderType && data.OrderType.length === 0)
  )
    return <p>Keine Sortierungs-Art angelegt...</p>;

  // Contruct Options
  let orderTypesOptions = [];
  if (orderTypeList) {
    orderTypesOptions = orderTypeList.map((orderType) => (
      <option key={orderType.name} value={orderType.name}>
        {orderType.description}
      </option>
    ));
  }

  // Handle if user selects another option
  const typeChanged = (e) => {
    const selectedValue = e.target.value;
    setOrderType(selectedValue);
    props.onChangeHandler(selectedValue);
  };

  return (
    <div>
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        Sortierungs-Art
      </label>
      <div className="inline-block relative w-64">
        <select
          className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          onChange={typeChanged}
          value={orderType}
          // required={true}
        >
          {orderTypesOptions}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
  );
};
