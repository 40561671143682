import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";

// GraphQL Query for TaskTypes. Its an enum typ in Graphql.
const TASKS_TYPES = gql`
  query TaskTypes {
    TaskType {
      description
      name
    }
  }
`;

export const TaskTypeDopdown = props => {
  const currentType = props.currentType;
  // Loading task types from GraphQL API
  const [taskType, setTaskType] = useState(null);
  const { data, loading, error } = useQuery(TASKS_TYPES);
  if (currentType && taskType !== currentType) {
    setTaskType(currentType);
  }

  // Check if data received, otherwise show some info.
  if (error) return <p>Error :(</p>;
  if (loading) return <p>Loading...</p>;
  if (
    !data ||
    !data.TaskType ||
    (data && data.TaskType && data.TaskType.length === 0)
  )
    return <p>No data...</p>;

  // Set defaultValue internal and for external components
  if (!taskType) {
    const defautltValue = data.TaskType[0].name;
    setTaskType(defautltValue);
    props.onChangeHandler(defautltValue);
  }

  // Contruct Options
  const taskTypesList = data.TaskType.map(taskType => (
    <option key={taskType.name} value={taskType.name}>
      {taskType.description}
    </option>
  ));

  // Handle if user selects another option
  const typeChanged = e => {
    const selectedValue = e.target.value;
    setTaskType(selectedValue);
    props.onChangeHandler(selectedValue);
  };
  // Send default option to consumer component
  // props.onChangeHandler(data.TaskType[0].name);

  return (
    <div>
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
        Aufgaben-Typ
      </label>
      <div className="inline-block relative w-64">
        <select
          className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
          onChange={typeChanged}
          defaultValue={taskType}
        >
          {taskTypesList}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </div>
  );
};
