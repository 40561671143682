import React, { Fragment } from "react";

export const FormInput = ({
  label,
  inputType,
  rows = 6,
  id,
  placeholder = "",
  required = false,
  value,
  onChangeEvent,
  dataIdx
}) => {
  return (
    <Fragment>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor={id}
      >
        {label}
        {required ? "*" : ""}
      </label>
      {inputType !== "textarea" && <input
        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        type={inputType}
        name={id}
        id={value}
        value={value}
        onChange={onChangeEvent}
        placeholder={placeholder}
        required={required}
        data-idx={dataIdx}
      />}
      {inputType === "textarea" && <textarea
        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        type={inputType}
        name={id}
        rows={rows}
        id={value}
        value={value}
        onChange={onChangeEvent}
        placeholder={placeholder}
        required={required}
        data-idx={dataIdx}
      />}
    </Fragment>
  );
};
