//
// useExpiration hook
//
import { useEffect, useState } from 'react';
import { formatDistanceToNowStrict } from 'date-fns'
import de from 'date-fns/locale/de';

import { Authentication } from "../Authentication";

const useExpiration = (refreshCycle = 1000) => {
  // Returns the current time
  // and queues re-renders every `refreshCycle` milliseconds (default: 100ms)

  const [now, setNow] = useState();

  useEffect(() => {
    // Regularly set time in state
    // (this will cause your component to re-render frequently)
    const intervalId = setInterval(
      () => {
        if (Authentication.getToken()) {
          return setNow(formatDistanceToNowStrict(new Date(Authentication.getPayload(Authentication.getToken()).exp * 1000),{locale: de}))
        } else {
          return null
        }
      },
      refreshCycle,
    );

    // Cleanup interval
    return () => clearInterval(intervalId);

    // Specify dependencies for useEffect
  },        [refreshCycle, setNow]);

  return now;
};

export default useExpiration