import React, { useState } from "react";
import { useSubscription } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
// import { Link, Redirect } from "react-router-dom";
import { ContentHeadline } from "../ContentHeadline";
import { SubMenuHeadline } from "../SubMenuHeadline";
import axios from "axios";

const GET_CLIENTS_WITH_YEAR_SUBSCRIPTION = gql`
  subscription ClientsAndYearsSubscription {
    Client {
      Years {
        description
        year_id
        portal_id
      }
      name
      client_id
    }
  }
`;

export const SelectTestPool = props => {
  // const currentClient = props.client;
  const currentYear = props.year;
  // const { loading, error, data } = useQuery(GET_CLIENTS_WITH_YEAR);
  const { loading, error, data } = useSubscription(
    GET_CLIENTS_WITH_YEAR_SUBSCRIPTION
  );
  const [importState, setImportState] = useState(false);

  const importBatminData = async () => {
    setImportState(true);
    const res = await axios.get(process.env.REACT_APP_YEAR_PROFESSION_IMPORT_URL);
    if (res.status >= 200 && res.status < 400) {
      setImportState(false);
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (data && data.Client && data.Client.length === 0)
    return (
      <div>
        <div className="mb-2">
          <button
            type="button"
            onClick={importBatminData}
            className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center mr-4"
          >
            Neue Jahre und Berufsrichtungen importieren
          </button>
          {importState && "Loading..."}
        </div>
        <p>Kein Testpool angelegt</p>
      </div>
    );

  const selectYear = (year, client) => {
    props.resetTest();
    props.selectYearAndClient({ year, client });
  };

  const yearList = client => {
    return client.Years.map(y => (
      <li
        onClick={() => selectYear(y, client)}
        key={y.year_id}
        className={`px-4 py-2 border border-solid border-gray-600 mr-2 flex justify-between items-center cursor-pointer`}
      >
        {currentYear && currentYear.year_id === y.year_id && (
          <svg
            className="w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
          </svg>
        )}
        {y.description}
      </li>
    ));
  };

  const clientList = data.Client.map(c => (
    <li key={c.client_id} className="mb-4">
      <SubMenuHeadline title={c.name}></SubMenuHeadline>
      <ul className="pl-4 flex">{yearList(c)}</ul>
    </li>
  ));

  return (
    <div className="container mx-auto pt-8">
      <ContentHeadline title={"Bitte wähle einen Testpool"}></ContentHeadline>
      <div className="mb-2">
        <button
          type="button"
          onClick={importBatminData}
          className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center mr-4"
        >
          Neue Jahre und Berufsrichtungen importieren
        </button>
        {importState && "Loading..."}
      </div>
      <ul>{clientList}</ul>
    </div>
  );
};
