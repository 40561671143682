import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { Link } from "react-router-dom";
import { ContentHeadline } from "../ContentHeadline";
import { useMutation } from "@apollo/react-hooks";

const GET_CLIENTS = gql`
  {
    Client {
      client_id
      name
      portal_url
    }
  }
`;

const DELETE_CLIENT = gql`
  mutation DeleteCLIENT($clientID: uuid) {
    delete_Client(where: { client_id: { _eq: $clientID } }) {
      affected_rows
    }
  }
`;

export const ClientList = () => {
  const { loading, error, data, refetch } = useQuery(GET_CLIENTS);
  // Always refetch on load
  refetch();
  const [deleteClient] = useMutation(DELETE_CLIENT);

  const deleteClientAction = clientID => {
    deleteClient({
      variables: {
        clientID: clientID
      }
    });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :( {error.Error}</p>;
  const TaskCell = props => <td className="px-2 py-4">{props.children}</td>;

  const clientList = data.Client.map(({ client_id, name }, i) => (
    <tr key={client_id} className={i % 2 && "bg-white"}>
      <TaskCell>
        <Link to={`/client/${client_id}`}>{name}</Link>
      </TaskCell>
      <TaskCell>
        <button type="button" onClick={() => deleteClientAction(client_id)}>
          Löschen
        </button>
      </TaskCell>
    </tr>
  ));

  return (
    <div>
      <ContentHeadline title="Kunden"></ContentHeadline>
      <Link
        className="mb-4 bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center"
        to="/client"
      >
        <svg
          className="fill-current w-4 h-4 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
        </svg>
        Neuen Kunden anlegen
      </Link>
      <div className="w-full">
        <table className="w-full">
          <thead>
            <tr className="bg-white text-left text-gray-400">
              <th className="font-normal px-2 py-2">Name</th>
              <th className="font-normal px-2 py-2">Aktionen</th>
            </tr>
          </thead>
          <tbody>{clientList}</tbody>
        </table>
      </div>
    </div>
  );
};
