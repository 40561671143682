import { createReducer } from "@reduxjs/toolkit";

const ClientReducer = createReducer(
  {
    client: null,
    year: null
  },
  {
    SELECT_YEAR_CLIENT: (state, action) => {
      return Object.assign(state, {
        client: action.payload.client,
        year: action.payload.year
      });
    }
  }
);

export default ClientReducer;
