import React, { Fragment, useState, useCallback, useEffect } from "react";
import { OrderTypeDopdown } from "./order-type-dropdown";
import { AnswerInputRow, TableHead } from "./answer-input-row";
import { FormInput, TextareaInput } from "../FormInput";
import { useDropzone } from "react-dropzone";

export const TaskFormFields = ({ taskType, taskChanged, currentTask }) => {
  const [orderType, setOrderType] = useState(null);
  // console.log(orderType);
  const blankAnswer = {
    bullet: "",
    label: "",
    text: "",
    comma: false,
    comma_points: 0,
    word_points: 0,
    points: 0,
    sample: false,
    label_position: taskType === "FILL_IN_SINGLE" ? "RIGHT" : "LEFT",
  };

  const initAnswers =
    currentTask && currentTask.Answers ? currentTask.Answers : [blankAnswer];

  const [answerState, setAnswerState] = useState(initAnswers);

  const blankTask = {
    name: "",
    question: "",
    header: "",
  };
  const initTask = currentTask ? currentTask : blankTask;

  const [taskState, setTaskState] = useState({ ...initTask });

  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (currentTask) {
      setTaskState(currentTask);
      setOrderType(currentTask.order_type);
      if (currentTask.Answers) {
        setAnswerState(currentTask.Answers);
      }
    }
  }, [currentTask]);

  // File upload setup
  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        setFiles([file]);
        const newTaskState = { ...taskState, image: file };
        setTaskState(newTaskState);
        taskChanged({ ...newTaskState, answerState });
      });
    },
    [answerState, taskState, taskChanged]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png", // Allow only images
    maxSize: 2000000, // 2MB
    multiple: false, // allow only one file
    onDropAccepted,
  });

  useEffect(() => {
    if (files.length === 0 && taskState && taskState.image_id) {
      const currentFile = new File([], taskState.File.filename, {
        path: taskState.File.filename,
      });
      setFiles([currentFile]);
    }
  }, [setFiles, taskState, files.length]);

  useEffect(() => {
    if (orderType) {
      setTaskState((state) => {
        state.order_type = orderType;
        return state;
      });
    }
  }, [orderType]);

  let acceptedFilesItems = [];
  if (files.length > 0) {
    acceptedFilesItems = files.map((file) =>
      file.path ? (
        <li key={file.path}>
          {file.path} -{" "}
          <button type="button" onClick={() => removeFile(file)}>
            X
          </button>
        </li>
      ) : (
        <li key={file.name}>
          {file.name} -{" "}
          <button type="button" onClick={() => removeFile(file)}>
            X
          </button>
        </li>
      )
    );
  }

  const addAnswer = () => {
    setAnswerState([...answerState, { ...blankAnswer }]);
  };

  const handleTaskChange = (e) => {
    const newTaskState = {
      ...taskState,
      [e.target.name]: e.target.value,
    };
    setTaskState(newTaskState);
    taskChanged({ ...newTaskState, answerState });
  };

  const answerLetters = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];

  const handleAnswerChange = (e) => {
    let updatedAnswers = [...answerState];
    console.log(e.target);
    console.log(e.image);
    let idx;
    if (e.image) {
      idx = e.idx;
      // handle image upload
      updatedAnswers[idx]["image"] = e.image;
      updatedAnswers[idx]["sort_order"] = idx;
    } else {
      idx = e.target.dataset.idx;
      // handle normal file change
      console.log(e.target.dataset);
      let value = e.target?.value;
      if (e.target.type === "checkbox") {
        value = e.target.checked;
      }
      const inputName = e.target.getAttribute("data-name");
      updatedAnswers[idx][inputName] = value;
      updatedAnswers[idx]["sort_order"] = idx;
    }
    if (taskState.order_type === "LETTERS") {
      updatedAnswers = updatedAnswers.map((a, i) => {
        a.bullet = answerLetters[i];
        return a;
      });
    }
    if (taskState.order_type === "NUMBERS") {
      updatedAnswers = updatedAnswers.map((a, i) => {
        a.bullet = i + 1;
        a.bullet = a.bullet + "";
        return a;
      });
    }

    // set sort order
    updatedAnswers = updatedAnswers.map((a, i) => {
      a.sort_order = i;
      return a;
    });

    setAnswerState(updatedAnswers);
    taskChanged({ ...taskState, answerState });
  };

  const singleAnswerDelete = (idx) => {
    const updatedAnswers = [...answerState];
    updatedAnswers.splice(idx, 1);
    setAnswerState(updatedAnswers);
    taskChanged({ ...taskState, answerState: updatedAnswers });
  };

  const removeFile = (file) => {
    // remove from task
    const newTaskState = {
      ...taskState,
      image: null,
      image_id: null,
    };
    setTaskState(newTaskState);
    taskChanged({ ...newTaskState, answerState });
    // remove file from file list
    setFiles([]);
  };

  switch (taskType) {
    case "MULTIPLE_CHOICE_TEXT":
      return (
        <Fragment>
          <Fragment>
            <div className="mb-8">
              <FormInput
                label="Aufgabenname"
                inputType="text"
                id="name"
                value={taskState.name}
                onChangeEvent={handleTaskChange}
                placeholder="Bitte einen Namen für die Aufgabe angeben"
                required={true}
              />
            </div>
            <div className="mb-8">
              <TextareaInput
                label="Aufgabenstellung"
                id="question"
                value={taskState.question}
                onChangeEvent={handleTaskChange}
                placeholder="Bitte eine Aufgabenstellung für die Aufgabe angeben"
              ></TextareaInput>
            </div>
          </Fragment>
          <div className="mb-8">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Aufgaben-Bild
            </label>
            <div
              {...getRootProps({
                className:
                  "dropzone flex justify-center text-gray-500 bg-gray-200 p-4 border-gray-200 rounded border-solid border",
              })}
            >
              <input {...getInputProps()} />
              <p>
                Dateien hier her ziehen, oder anklicken um eine Datei zu
                selektieren
              </p>
            </div>
            {acceptedFilesItems.length > 0 && (
              <aside className="flex">
                <h4>Aktuelle Datei: </h4>
                <ul>{acceptedFilesItems}</ul>
              </aside>
            )}
          </div>
          <div className="mb-8">
            <OrderTypeDopdown
              currentType={orderType}
              onChangeHandler={setOrderType}
            ></OrderTypeDopdown>
          </div>
          <div>
            <div className="mb-2">
              <button
                type="button"
                onClick={addAnswer}
                className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center mr-4"
              >
                Antwort hinzufügen
              </button>
            </div>
            <table className="w-full">
              <TableHead fields={["bullet", "text", "points"]}></TableHead>
              <tbody>
                {answerState.map((val, idx) => (
                  <AnswerInputRow
                    key={`answer-${idx}`}
                    idx={idx}
                    answerState={answerState}
                    fields={["bullet", "text", "points"]}
                    handleAnswerChange={handleAnswerChange}
                    handleDelete={singleAnswerDelete}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </Fragment>
      );
    case "MULTIPLE_CHOICE_IMAGE":
      return (
        <Fragment>
          <Fragment>
            <div className="mb-8">
              <FormInput
                label="Aufgabenname"
                inputType="text"
                id="name"
                value={taskState.name}
                onChangeEvent={handleTaskChange}
                placeholder="Bitte einen Namen für die Aufgabe angeben"
                required={true}
              />
            </div>
            <div className="mb-8">
              <TextareaInput
                label="Aufgabenstellung"
                id="question"
                value={taskState.question}
                onChangeEvent={handleTaskChange}
                placeholder="Bitte eine Aufgabenstellung für die Aufgabe angeben"
              ></TextareaInput>
            </div>
          </Fragment>
          <div className="mb-8">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Aufgaben-Bild
            </label>
            <div
              {...getRootProps({
                className:
                  "dropzone flex justify-center text-gray-500 bg-gray-200 p-4 border-gray-200 rounded border-solid border",
              })}
            >
              <input {...getInputProps()} />
              <p>
                Dateien hier her ziehen, oder anklicken um eine Datei zu
                selektieren
              </p>
            </div>
            {acceptedFilesItems.length > 0 && (
              <aside className="flex">
                <h4>Aktuelle Datei: </h4>
                <ul>{acceptedFilesItems}</ul>
              </aside>
            )}
          </div>
          <div className="mb-8">
            <OrderTypeDopdown
              currentType={orderType}
              onChangeHandler={setOrderType}
            ></OrderTypeDopdown>
          </div>
          <div>
            <div className="mb-2">
              <button
                type="button"
                onClick={addAnswer}
                className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center mr-4"
              >
                Antwort hinzufügen
              </button>
            </div>
            <table className="w-full">
              <TableHead fields={["bullet", "image", "points"]}></TableHead>
              <tbody>
                {answerState.map((val, idx) => (
                  <AnswerInputRow
                    key={`answer-${idx}`}
                    idx={idx}
                    answerState={answerState}
                    fields={["bullet", "image", "points"]}
                    handleAnswerChange={handleAnswerChange}
                    handleDelete={singleAnswerDelete}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </Fragment>
      );
    case "FILL_IN_SINGLE":
      return (
        <Fragment>
          <Fragment>
            <div className="mb-8">
              <FormInput
                label="Aufgabenname"
                inputType="text"
                id="name"
                value={taskState.name}
                onChangeEvent={handleTaskChange}
                placeholder="Bitte einen Namen für die Aufgabe angeben"
                required={true}
              />
            </div>
            <div className="mb-8">
              <TextareaInput
                label="Aufgabenstellung"
                id="question"
                value={taskState.question}
                onChangeEvent={handleTaskChange}
                placeholder="Bitte eine Aufgabenstellung für die Aufgabe angeben"
              ></TextareaInput>
            </div>
          </Fragment>
          <div className="mb-8">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Aufgaben-Bild
            </label>
            <div
              {...getRootProps({
                className:
                  "dropzone flex justify-center text-gray-500 bg-gray-200 p-4 border-gray-200 rounded border-solid border",
              })}
            >
              <input {...getInputProps()} />
              <p>
                Dateien hier her ziehen, oder anklicken um eine Datei zu
                selektieren
              </p>
            </div>
            {acceptedFilesItems.length > 0 && (
              <aside className="flex">
                <h4>Aktuelle Datei: </h4>
                <ul>{acceptedFilesItems}</ul>
              </aside>
            )}
          </div>
          <div className="mb-8">
            <OrderTypeDopdown
              currentType={orderType}
              onChangeHandler={setOrderType}
            ></OrderTypeDopdown>
          </div>
          <div className="mb-8">
            <FormInput
              label="Antwort-Überschrift"
              inputType="text"
              id="header"
              value={taskState.header}
              onChangeEvent={handleTaskChange}
              placeholder="Bitte eine Überschrift für die Aufgabe angeben"
            />
          </div>
          <div>
            <table className="w-full">
              <TableHead
                fields={["bullet", "label", "text", "points"]}
              ></TableHead>
              <tbody>
                {answerState.map((val, idx) => (
                  <AnswerInputRow
                    key={`answer-${idx}`}
                    idx={idx}
                    answerState={answerState}
                    fields={["bullet", "label", "text", "points"]}
                    handleAnswerChange={handleAnswerChange}
                    handleDelete={singleAnswerDelete}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </Fragment>
      );
    case "FILL_IN_MULTIPLE":
      return (
        <Fragment>
          <Fragment>
            <div className="mb-8">
              <FormInput
                label="Aufgabenname"
                inputType="text"
                id="name"
                value={taskState.name}
                onChangeEvent={handleTaskChange}
                placeholder="Bitte einen Namen für die Aufgabe angeben"
                required={true}
              />
            </div>
            <div className="mb-8">
              <TextareaInput
                label="Aufgabenstellung"
                id="question"
                value={taskState.question}
                onChangeEvent={handleTaskChange}
                placeholder="Bitte eine Aufgabenstellung für die Aufgabe angeben"
              ></TextareaInput>
            </div>
          </Fragment>
          <div className="mb-8">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Aufgaben-Bild
            </label>
            <div
              {...getRootProps({
                className:
                  "dropzone flex justify-center text-gray-500 bg-gray-200 p-4 border-gray-200 rounded border-solid border",
              })}
            >
              <input {...getInputProps()} />
              <p>
                Dateien hier her ziehen, oder anklicken um eine Datei zu
                selektieren
              </p>
            </div>
            {acceptedFilesItems.length > 0 && (
              <aside className="flex">
                <h4>Aktuelle Datei: </h4>
                <ul>{acceptedFilesItems}</ul>
              </aside>
            )}
          </div>
          <div className="mb-8">
            <OrderTypeDopdown
              currentType={orderType}
              onChangeHandler={setOrderType}
            ></OrderTypeDopdown>
          </div>
          <div className="mb-8">
            <FormInput
              label="Antwort-Überschrift"
              inputType="text"
              id="header"
              value={taskState.header}
              onChangeEvent={handleTaskChange}
              placeholder="Bitte eine Überschrift für die Aufgabe angeben"
            />
          </div>
          <div>
            <div className="mb-2">
              <button
                type="button"
                onClick={addAnswer}
                className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center mr-4"
              >
                Antwort hinzufügen
              </button>
            </div>
            <table className="w-full">
              <TableHead
                fields={["bullet", "label", "text", "points"]}
              ></TableHead>
              <tbody>
                {answerState.map((val, idx) => (
                  <AnswerInputRow
                    key={`answer-${idx}`}
                    idx={idx}
                    answerState={answerState}
                    fields={["bullet", "label", "text", "points"]}
                    handleAnswerChange={handleAnswerChange}
                    handleDelete={singleAnswerDelete}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </Fragment>
      );
    case "FILL_IN_GAP":
      return (
        <Fragment>
          <Fragment>
            <div className="mb-8">
              <FormInput
                label="Aufgabenname"
                inputType="text"
                id="name"
                value={taskState.name}
                onChangeEvent={handleTaskChange}
                placeholder="Bitte einen Namen für die Aufgabe angeben"
                required={true}
              />
            </div>
            <div className="mb-8">
              <TextareaInput
                label="Aufgabenstellung"
                id="question"
                value={taskState.question}
                onChangeEvent={handleTaskChange}
                placeholder="Bitte eine Aufgabenstellung für die Aufgabe angeben"
              ></TextareaInput>
            </div>
          </Fragment>
          <div className="mb-8">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Aufgaben-Bild
            </label>
            <div
              {...getRootProps({
                className:
                  "dropzone flex justify-center text-gray-500 bg-gray-200 p-4 border-gray-200 rounded border-solid border",
              })}
            >
              <input {...getInputProps()} />
              <p>
                Dateien hier her ziehen, oder anklicken um eine Datei zu
                selektieren
              </p>
            </div>
            {acceptedFilesItems.length > 0 && (
              <aside className="flex">
                <h4>Aktuelle Datei: </h4>
                <ul>{acceptedFilesItems}</ul>
              </aside>
            )}
          </div>
          <div className="mb-8">
            <OrderTypeDopdown
              currentType={orderType}
              onChangeHandler={setOrderType}
            ></OrderTypeDopdown>
          </div>
          <div className="mb-8">
            <FormInput
              label="Antwort-Überschrift"
              inputType="text"
              id="header"
              value={taskState.header}
              onChangeEvent={handleTaskChange}
              placeholder="Bitte eine Überschrift für die Aufgabe angeben"
            />
          </div>
          <div>
            <div className="mb-2">
              <button
                type="button"
                onClick={addAnswer}
                className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center mr-4"
              >
                Antwort hinzufügen
              </button>
            </div>
            <table className="w-full">
              <TableHead
                fields={[
                  "label",
                  "text",
                  "comma",
                  "word_points",
                  "comma_points",
                  "sample",
                ]}
              ></TableHead>
              <tbody>
                {answerState.map((val, idx) => (
                  <AnswerInputRow
                    key={`answer-${idx}`}
                    idx={idx}
                    answerState={answerState}
                    fields={[
                      "label",
                      "text",
                      "comma",
                      "word_points",
                      "comma_points",
                      "sample",
                    ]}
                    handleAnswerChange={handleAnswerChange}
                    handleDelete={singleAnswerDelete}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </Fragment>
      );
    case "RATING_SKALA":
      return (
        <Fragment>
          <Fragment>
            <div className="mb-8">
              <FormInput
                label="Aufgabenname"
                inputType="text"
                id="name"
                value={taskState.name}
                onChangeEvent={handleTaskChange}
                placeholder="Bitte einen Namen für die Aufgabe angeben"
                required={true}
              />
            </div>
            <div className="mb-8">
              <TextareaInput
                label="Aufgabenstellung"
                id="question"
                value={taskState.question}
                onChangeEvent={handleTaskChange}
                placeholder="Bitte eine Aufgabenstellung für die Aufgabe angeben"
              ></TextareaInput>
            </div>
          </Fragment>
          <div className="mb-8">
            <FormInput
              label="Antwort-Überschrift"
              inputType="text"
              id="header"
              value={taskState.header}
              onChangeEvent={handleTaskChange}
              placeholder="Bitte eine Überschrift für die Aufgabe angeben"
            />
          </div>
          <div>
            <div className="mb-2">
              <button
                type="button"
                onClick={addAnswer}
                className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center mr-4"
              >
                Antwort hinzufügen
              </button>
            </div>
            <table className="w-full">
              <TableHead fields={["label", "points"]}></TableHead>
              <tbody>
                {answerState.map((val, idx) => (
                  <AnswerInputRow
                    key={`answer-${idx}`}
                    idx={idx}
                    answerState={answerState}
                    fields={["label", "points"]}
                    handleAnswerChange={handleAnswerChange}
                    handleDelete={singleAnswerDelete}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </Fragment>
      );
    default:
      return <p>Bitte Aufgabentyp auswählen.</p>;
  }
};
