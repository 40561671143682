import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Link, useParams } from "react-router-dom";
import { FormInput } from "../FormInput";
import { ContentHeadline } from "../ContentHeadline";
import { Redirect } from "react-router-dom";

const ADD_MODULE = gql`
  mutation AddModule($module: [TestModule_insert_input!]!) {
    insert_TestModule(objects: $module) {
      affected_rows
    }
  }
`;

const UPDATE_MODULE = gql`
  mutation UpdateModule($moduleID: uuid, $module: TestModule_set_input!) {
    update_TestModule(
      _set: $module
      where: { test_module_id: { _eq: $moduleID } }
    ) {
      affected_rows
    }
  }
`;

const GET_MODULE_BY_ID = gql`
  query ModuleByID($moduleID: uuid!) {
    TestModule_by_pk(test_module_id: $moduleID) {
      test_module_id
      name
      description
      weight
      instruction
    }
  }
`;

export const AddEditModule = (props) => {
  const variantID =
    props.variant && props.variant.test_variant_id
      ? props.variant.test_variant_id
      : null;

  const params = useParams();
  let moduleID = null;
  if (params && params.moduleID) {
    moduleID = params.moduleID;
  }
  const blankModule = {
    name: "",
    description: "",
    instruction: "",
    weight: 0,
  };
  const [moduleState, setModuleState] = useState([{ ...blankModule }]);
  const [redirectState, setRedirectState] = useState(false);

  const skip = moduleID ? false : true;
  const editMode = !skip;
  const { loading, error, data } = useQuery(GET_MODULE_BY_ID, {
    variables: {
      moduleID,
    },
    skip,
    fetchPolicy: "no-cache",
  });

  const [addModule] = useMutation(ADD_MODULE);
  const [updateModule] = useMutation(UPDATE_MODULE);

  if (loading) return <p>Loading...</p>;
  if (error) console.error(error);
  if (data && data.TestModule_by_pk) {
    if (!moduleState.test_module_id) {
      setModuleState(data.TestModule_by_pk);
    }
  }

  const handleModuleChange = (e) => {
    setModuleState({
      ...moduleState,
      [e.target.name]: e.target.value,
    });
  };

  const createNewModule = (e) => {
    e.preventDefault();
    if (!editMode) {
      addModule({
        variables: {
          module: {
            description: moduleState.description,
            instruction: moduleState.instruction,
            name: moduleState.name,
            weight: moduleState.weight,
            test_variant_id: variantID,
          },
        },
      });
    } else {
      // Update module
      updateModule({
        variables: {
          moduleID: moduleID,
          module: {
            description: moduleState.description,
            instruction: moduleState.instruction,
            name: moduleState.name,
            weight: moduleState.weight,
            // test_variant_id: variantID
          },
        },
      }).then(() => {
        console.log("updated");
      });
    }
    setRedirectState(true);
  };

  if (redirectState) {
    return <Redirect to="/professions" />;
  }

  return (
    <div className="container mx-auto pt-8">
      <form
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={createNewModule}
      >
        <div className="w-full px-3">
          <ContentHeadline title="Neues Modul anlegen"></ContentHeadline>
          <div className="mb-8">
            <FormInput
              label="Name"
              inputType="text"
              id="name"
              value={moduleState.name}
              onChangeEvent={handleModuleChange}
              placeholder="Bitte einen Namen für das Modul eingeben"
              required={true}
            />
          </div>
          <div className="mb-8">
            <FormInput
              label="Beschreibung (Short name)"
              inputType="textarea"
              id="description"
              value={moduleState.description}
              onChangeEvent={handleModuleChange}
              placeholder="(Optional) Bitte eine Beschreibung für das Modul eingeben"
            />
          </div>
          <div className="mb-8">
            <FormInput
              label="Instruktion"
              inputType="textarea"
              id="instruction"
              value={moduleState.instruction}
              onChangeEvent={handleModuleChange}
              placeholder="(Optional) Bitte eine Instruktion für das Modul eingeben"
            />
          </div>
          <div className="mb-8">
            <FormInput
              label="Gewichtung"
              inputType="text"
              id="weight"
              value={moduleState.weight}
              onChangeEvent={handleModuleChange}
              placeholder="(Optional) Bitte eine Gewichtung für das Modul eingeben"
            />
          </div>
          <div className="flex justify-end">
            <Link
              className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center mr-4"
              to="/professions"
            >
              Abbrechen
            </Link>
            <button
              className="bg-purple-500 hover:bg-purple-700 text-white py-2 px-4"
              type="submit"
            >
              {editMode ? "Modul bearbeiten" : "Modul anlegen"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
