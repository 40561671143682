import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
// import "./index.css";
import "./styles/tailwind.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import rootReducer from "./reducers";
import { configureStore } from "@reduxjs/toolkit";
import { loadState, saveState } from "./localstorage";
import SetupAxios from "./setup-axios";

SetupAxios.setupAxios();

const persistedState = loadState();

const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState
});

// TODO: Add lodash.throttle to throttle subscribtion
store.subscribe(() => {
  saveState(store.getState());
});

// , window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
