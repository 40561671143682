import { createReducer } from "@reduxjs/toolkit";

const TestReducer = createReducer(
  {
    profession: null,
    variant: null,
    testModule: null
  },
  {
    SELECT_PROFESSION: (state, action) => {
      return { ...state, profession: action.payload };
    },
    SELECT_VARIANT: (state, action) => {
      return { ...state, variant: action.payload };
    },
    SELECT_TEST_MODULE: (state, action) => {
      return { ...state, testModule: action.payload };
    },
    RESET_TEST: state => {
      return { ...state, profession: null, variant: null, testModule: null };
    }
  }
);

export default TestReducer;
