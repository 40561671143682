import React, { useState, useEffect, useRef } from "react";
import gql from "graphql-tag";
import axios from "axios";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Link, useParams } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { TaskTypeDopdown } from "./task-type-dropdown";
import { TaskFormFields } from "./task-form-fields";
import { ContentHeadline } from "../ContentHeadline";

const GET_TASK_BY_ID = gql`
  query TaskByID($taskID: uuid!) {
    Task_by_pk(task_id: $taskID) {
      header
      image_id
      name
      question
      order_type
      task_id
      type
      Answers(order_by: { sort_order: asc }) {
        answer_id
        bullet
        comma
        comma_points
        image_id
        label
        label_position
        points
        sample
        sort_order
        task_id
        text
        word_points
        LabelPosition {
          description
          name
        }
        File {
          file_id
          filename
          size
        }
      }
      File {
        file_id
        filename
        size
      }
    }
  }
`;

const ADD_TASK = gql`
  mutation AddTask($task: [Task_insert_input!]!) {
    insert_Task(objects: $task) {
      affected_rows
    }
  }
`;

const UPDATE_TASK = gql`
  mutation UpdateTask($taskID: uuid, $task: Task_set_input) {
    update_Task(_set: $task, where: { task_id: { _eq: $taskID } }) {
      affected_rows
    }
  }
`;

const ADD_ANSWERS = gql`
  mutation AddAnswers($answers: [Answer_insert_input!]!) {
    insert_Answer(objects: $answers) {
      affected_rows
    }
  }
`;

const DELETE_ANSWERS = gql`
  mutation DeleteAnswers($taskID: uuid) {
    delete_Answer(where: { task_id: { _eq: $taskID } }) {
      affected_rows
    }
  }
`;

const ADD_FILE = gql`
  mutation AddFile($file: [File_insert_input!]!) {
    insert_File(objects: $file) {
      returning {
        file_id
      }
    }
  }
`;

const cleanAnswersInTask = (task) => {
  const answers = task.Answers;
  const newAnswers = answers.map((a) => {
    if (a["__typename"]) {
      delete a["__typename"];
    }
    return a;
  });
  task.Answers = newAnswers;
  return task;
};

const addTaskAction = (addTaskFunc, taskType, task, moduleID, file) => {
  if (!file) {
    return addTaskFunc({
      variables: {
        task: {
          name: task.name,
          question: task.question,
          header: task.header,
          type: taskType,
          test_module_id: moduleID,
          order_type: task.order_type ? task.order_type : null,
          Answers: { data: task.answerState },
        },
      },
    });
  }
  addTaskFunc({
    variables: {
      task: {
        name: task.name,
        question: task.question,
        header: task.header,
        type: taskType,
        test_module_id: moduleID,
        image_id: file.file_id,
        order_type: task.order_type ? task.order_type : null,
        Answers: { data: task.answerState },
      },
    },
  });
};

export const AddEditTask = (props) => {
  const params = useParams();
  let taskID = null;
  if (params && params.taskID) {
    taskID = params.taskID;
  }

  const [taskType, setTaskType] = useState(null);
  const [taskState, setTaskState] = useState({});
  const [redirectState, setRedirectState] = useState(false);
  const moduleID =
    props.testModule && props.testModule.test_module_id
      ? props.testModule.test_module_id
      : null;
  const [addTask] = useMutation(ADD_TASK);

  const [addFile] = useMutation(ADD_FILE);

  const [updateTask] = useMutation(UPDATE_TASK);

  const [addAnswers] = useMutation(ADD_ANSWERS);

  const [deleteAnswers] = useMutation(DELETE_ANSWERS, {
    fetchPolicy: "no-cache",
  });

  const skip = taskID ? false : true;
  const editMode = !skip;
  const { loading, error, data } = useQuery(GET_TASK_BY_ID, {
    variables: {
      taskID,
    },
    skip: skip,
    fetchPolicy: "no-cache",
  });
  let currentTask = useRef(null);

  useEffect(() => {
    if (data && data.Task_by_pk) {
      currentTask.current = cleanAnswersInTask(data.Task_by_pk);

      setTaskType(currentTask.current.type);

      setTaskState(currentTask.current);
    }
  }, [data]);

  if (loading) return <p>Loading...</p>;
  if (error) console.error(error);

  const typeChanged = (taskTypeInput) => {
    setTaskType(taskTypeInput);
  };

  const storeFile = (file) => {
    const data = new FormData();
    data.append("upload[]", file);
    axios.post("/upload", data);
    return addFile({
      variables: {
        file: {
          filename: file.path,
          size: file.size,
        },
      },
    });
  };

  const submitTask = async (e) => {
    e.preventDefault();
    // update or insert?
    if (!currentTask.current) {
      // insert
      // there is no task image
      if (!taskState.image) {
        // store answer images first
        Promise.all(
          taskState.answerState.map(async (ans) => {
            if (ans.image) {
              const result = await storeFile(ans.image);
              const returningFile = result.data.insert_File.returning[0];
              ans.image_id = returningFile.file_id;
              delete ans.image;
            }
            return ans;
          })
        ).then((answersWithStoredImages) => {
          // store task and answers itself
          taskState.answerState = answersWithStoredImages;
          addTaskAction(addTask, taskType, taskState, moduleID, undefined);
          setRedirectState(true);
        });
      } else {
        // there is a task image
        // store answer images first
        Promise.all(
          taskState.answerState.map(async (ans) => {
            if (ans.image) {
              const result = await storeFile(ans.image);
              const returningFile = result.data.insert_File.returning[0];
              ans.image_id = returningFile.file_id;
              delete ans.image;
            }
            return ans;
          })
        ).then((answersWithStoredImages) => {
          const data = new FormData();
          data.append("upload[]", taskState.image);
          axios.post("/upload", data);
          addFile({
            variables: {
              file: {
                filename: taskState.image.path,
                size: taskState.image.size,
              },
            },
          }).then((res) => {
            const returningFile = res.data.insert_File.returning[0];
            const currentTaskState = taskState;
            currentTaskState.Answers = answersWithStoredImages;
            addTaskAction(
              addTask,
              taskType,
              currentTaskState,
              moduleID,
              returningFile
            );
            setRedirectState(true);
          });
        });
      }
    } else {
      //update
      if (taskState.image) {
        const data = new FormData();
        data.append("upload[]", taskState.image);
        axios.post("/upload", data);
        addFile({
          variables: {
            file: {
              filename: taskState.image.path,
              size: taskState.image.size,
            },
          },
        }).then((res) => {
          const returningFile = res.data.insert_File.returning[0];
          updateTask({
            variables: {
              taskID: taskState.task_id,
              task: {
                name: taskState.name,
                question: taskState.question,
                header: taskState.header,
                image_id: returningFile.file_id,
                order_type: taskState.order_type ? taskState.order_type : null,
              },
            },
          }).then(async (rt) => {
            if (taskState.answerState) {
              Promise.all(
                taskState.answerState.map(async (ans) => {
                  // set task id
                  ans.task_id = taskState.task_id;
                  // remove old id
                  delete ans.answer_id;
                  // remove label position object
                  delete ans.LabelPosition;
                  if (ans.image) {
                    const result = await storeFile(ans.image);
                    const returningFile = result.data.insert_File.returning[0];
                    ans.image_id = returningFile.file_id;
                    delete ans.image;
                  }
                  return ans;
                })
              ).then((answersWithStoredImages) => {
                deleteAnswers({
                  variables: {
                    taskID: taskState.task_id,
                  },
                }).then((rd) => {
                  addAnswers({
                    variables: {
                      answers: answersWithStoredImages,
                    },
                  });
                });
              });
            }
          });
        });
      } else {
        updateTask({
          variables: {
            taskID: taskState.task_id,
            task: {
              name: taskState.name,
              question: taskState.question,
              header: taskState.header,
              image_id: taskState.image_id ? taskState.image_id : null,
              order_type: taskState.order_type ? taskState.order_type : null,
            },
          },
        }).then(async (rt) => {
          if (taskState.answerState) {
            const answersWithStoredImages = await Promise.all(
              taskState.answerState.map(async (ans) => {
                // set task id
                ans.task_id = taskState.task_id;
                // remove old id
                delete ans.answer_id;
                // remove label position object
                delete ans.LabelPosition;
                if (ans.image) {
                  const result = await storeFile(ans.image);
                  const returningFile = result.data.insert_File.returning[0];
                  ans.image_id = returningFile.file_id;
                  delete ans.image;
                }
                return ans;
              })
            );
            await deleteAnswers({
              variables: {
                taskID: taskState.task_id,
              },
            });
            answersWithStoredImages.map((a) => {
              delete a.File;
              return a;
            });
            addAnswers({
              variables: {
                answers: answersWithStoredImages,
              },
            });
          }
        });
      }
      setRedirectState(true);
    }
  };

  if (redirectState) {
    return <Redirect to="/professions" />;
  }

  return (
    <div className="container mx-auto pt-8">
      <form
        onSubmit={(e) => submitTask(e)}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="w-full px-3">
          <ContentHeadline
            title={
              currentTask.current
                ? `Aufgabe ${currentTask.current.name} bearbeiten`
                : `Neue Aufgabe anlegen`
            }
          ></ContentHeadline>
          {!editMode && (
            <div className="mb-8">
              <TaskTypeDopdown
                onChangeHandler={typeChanged}
                currentType={taskType}
              ></TaskTypeDopdown>
            </div>
          )}
          <TaskFormFields
            taskType={taskType}
            currentTask={currentTask.current}
            taskChanged={setTaskState}
          />
          <div className="flex justify-end">
            <Link
              className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center mr-4"
              to="/professions"
            >
              Abbrechen
            </Link>
            <button
              className="bg-purple-500 hover:bg-purple-700 text-white py-2 px-4"
              type="submit"
            >
              {editMode ? "Aufgabe bearbeiten" : "Aufgabe hinzufügen"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
