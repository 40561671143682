import React from "react";
import { NavLink } from "react-router-dom";

export const Navigation = () => {
  const menuItems = [
    { id: 1, name: "Testpool auswählen", link: "/" },
    { id: 4, name: "Berufsrichtungen", link: "/professions" },
    { id: 3, name: "Export", link: "/export-history" },
    { id: 2, name: "Kunden verwalten", link: "/clients" }
  ];

  const navLinks = menuItems.map(item => (
    <li className="mr-6" key={item.id}>
      <NavLink
        exact
        activeClassName="font-bold"
        className="text-white hover:underline"
        to={item.link}
      >
        {item.name}
      </NavLink>
    </li>
  ));
  return <ul className="inline-flex items-center">{navLinks}</ul>;
};
