import axios from "axios";

export default {
  setupAxios() {
    let url;
    if (window && window.REACT_APP_ONLINE_TEST_API_URL) {
      url = window.REACT_APP_ONLINE_TEST_API_URL;
    } else {
      url = process.env.REACT_APP_ONLINE_TEST_API_URL;
    }
    axios.defaults.baseURL = url;
  }
};
