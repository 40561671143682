import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { useHistory, Link } from "react-router-dom";
import { SubMenuHeadline } from "../SubMenuHeadline";

const GET_PROFESSIONS = gql`
  query Professions($yearID: uuid) {
    Profession(where: { Year: { year_id: { _eq: $yearID } } }) {
      name
      profession_id
      TestVariants(order_by: { name: asc }) {
        name
        test_variant_id
      }
    }
  }
`;

const SETCOPY = gql`
  mutation SetCopy($source: uuid, $destination: uuid) {
    insert_Copy(
      objects: {
        source_id: $source
        target_id: $destination
        copy_type: TestVariant
      }
    ) {
      affected_rows
    }
  }
`;

export const ProfessionsList = (props) => {
  const currentVariant = props.variant;
  let history = useHistory();
  if (!props.year) {
    history.push("/");
  }

  const yearID = props.year ? props.year.year_id : null;
  const [firstSelected, setFirstSelected] = useState(null);
  const { loading, error, data } = useQuery(GET_PROFESSIONS, {
    variables: {
      yearID: yearID,
    },
  });

  const [copyVariant] = useMutation(SETCOPY);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  if (data && data.Profession && data.Profession.length === 0)
    return <p>Keine Berufsrichtung angelegt</p>;

  const selectProfAndVar = (profession, testVariant) => {
    // reset test module
    props.selectTestModule(null);
    // select profession and variant
    props.selectProfession(profession);
    props.selectVariant(testVariant);
  };

  const handleCopy = (id) => {
    if (!firstSelected) {
      setFirstSelected(id);
    }
    if (firstSelected && firstSelected === id) {
      setFirstSelected(null)
    }
    if (firstSelected && firstSelected !== id) {
      copyVariant({variables: { source: firstSelected, destination: id}})
      setFirstSelected(null);
    }
  };

  const variantsList = (profession) => {
    return profession.TestVariants.map((tv) => (
      <li key={tv.test_variant_id} className="pl-6 font-light cursor-pointer">
        <span
          className={
            currentVariant &&
            currentVariant.test_variant_id === tv.test_variant_id
              ? "font-bold"
              : ""
          }
        >
          <span onClick={() => selectProfAndVar(profession, tv)}>
            Variante {tv.name}{" "}
          </span>
          <Link
            className="bg-transparent text-white px-4 inline-flex items-center text-sm"
            to={`/variants/${tv.test_variant_id}`}
          >
            <svg
              className="fill-current w-3 h-3"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M12.3 3.7l4 4L4 20H0v-4L12.3 3.7zm1.4-1.4L16 0l4 4-2.3 2.3-4-4z" />
            </svg>
          </Link>
          {!firstSelected && (
            <div
              className="inline-block z-10"
              onClick={() => handleCopy(tv.test_variant_id)}
            >
              <svg
                className="fill-current w-3 h-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M20,2H10C8.897,2,8,2.897,8,4v4H4c-1.103,0-2,0.897-2,2v10c0,1.103,0.897,2,2,2h10c1.103,0,2-0.897,2-2v-4h4 c1.103,0,2-0.897,2-2V4C22,2.897,21.103,2,20,2z M4,20V10h10l0.002,10H4z M20,14h-4v-4c0-1.103-0.897-2-2-2h-4V4h10V14z" />
              </svg>
            </div>
          )}
          {firstSelected && (
            <div
              className="inline-block z-10"
              onClick={() => handleCopy(tv.test_variant_id)}
            >
              <svg
                className="fill-current w-3 h-3"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M20,11V5c0-1.103-0.897-2-2-2h-3c0-0.552-0.447-1-1-1H8C7.447,2,7,2.448,7,3H4C2.897,3,2,3.897,2,5v13c0,1.103,0.897,2,2,2 h7c0,1.103,0.897,2,2,2h7c1.103,0,2-0.897,2-2v-7C22,11.897,21.103,11,20,11z M11,13v5H4V5h3v2h8V5h3v6h-5 C11.897,11,11,11.897,11,13z M13,20v-7h7l0.001,7H13z" />
              </svg>
            </div>
          )}
        </span>
      </li>
    ));
  };

  const professionList = data.Profession.map((p) => (
    <li className="mb-4" key={p.profession_id}>
      {p.name}
      <ul>{variantsList(p)}</ul>
    </li>
  ));

  return (
    <div className="text-white">
      <SubMenuHeadline title="Tests"></SubMenuHeadline>
      <ul>{professionList}</ul>
    </div>
  );
};
