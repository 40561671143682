import { connect } from "react-redux";
import {
  SelectTestModule,
  SelectProfession,
  SelectVariant,
  SelectYearAndClient,
  ResetTest
} from "../actions";
import { TaskList, AddEditTask } from "../components/Tasks";
import { ModulesList, AddEditModule } from "../components/Modules";
import { SelectTestPool } from "../components/SelectTestPool";
import { ExportHistory } from "../components/ExportHistory";
import { ProfessionsList } from "../components/Professions";
import { Header } from "../components/Header";

const mapStateToProps = (state, props) => {
  return {
    ...props,
    ...state.TestReducer,
    ...state.ClientReducer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectTestModule: testModule => dispatch(SelectTestModule(testModule)),
    selectProfession: profession => dispatch(SelectProfession(profession)),
    selectVariant: variant => dispatch(SelectVariant(variant)),
    selectYearAndClient: yearAndClient =>
      dispatch(SelectYearAndClient(yearAndClient)),
    resetTest: () => dispatch(ResetTest())
  };
};

export const ModulesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModulesList);

export const TaskListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskList);

export const AddEditModuleContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditModule);

export const AddEditTaskContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditTask);

export const ProfessionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfessionsList);

export const TestPoolContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectTestPool);

export const ExportContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExportHistory);

export const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
