import React from "react";

import { Authentication } from "../../Authentication";
import { AuthButton } from "../AuthButton";
import { Navigation } from "../Navigation";
import { useExpiration } from "../../hooks";

export const Header = props => {
  const experiesIn = useExpiration(100);

  const client = props.client?.name;
  const year = props.year?.description;
  return (
    <div className="flex">
      <div className="flex justify-between w-full bg-teal-600 h-12 pl-2">
        <Navigation />
        <div className="inline-flex items-center">
          <p className="text-xs text-white">
            {client} - {year}
          </p>
          {Authentication.getToken && <p className=" ml-2 text-xs text-white">
            Sitzung läuft aus in {experiesIn}
          </p>}
          <AuthButton
            isAuthenticated={Authentication.getToken()}
            signoutAction={Authentication.signout}
          />
        </div>
      </div>
    </div>
  );
};
