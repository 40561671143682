import React, { useState, useEffect } from "react";
import { gql } from "apollo-boost";
import { Link } from "react-router-dom";
import {
  useSubscription,
  useMutation,
  useLazyQuery,
} from "@apollo/react-hooks";
import Rodal from "rodal";
import "rodal/lib/rodal.css";

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: "base",
});

const TASKS_SUBSCRIPTION = gql`
  subscription Task($moduleID: uuid) {
    Task(
      where: { test_module_id: { _eq: $moduleID } }
      order_by: { name: asc }
    ) {
      TaskType {
        description
      }
      name
      task_id
    }
  }
`;

const DELETE_TASK = gql`
  mutation DeleteTask($taskID: uuid) {
    delete_Task(where: { task_id: { _eq: $taskID } }) {
      affected_rows
    }
  }
`;

const GET_CLIENTS_WITH_YEAR_AND_PROFESSIONS = gql`
  query ClientsAndYearsAndProfessions($yearID: uuid) {
    Year(where: { year_id: { _eq: $yearID } }) {
      description
      client_id
      year_id
      Professions {
        name
        profession_id
        TestVariants {
          name
          test_variant_id
          TestModules {
            name
            test_module_id
          }
        }
      }
    }
  }
`;

const SETCOPY = gql`
  mutation SetCopy($source: uuid, $destination: uuid) {
    insert_Copy(
      objects: {
        source_id: $source
        target_id: $destination
        copy_type: TestTask
      }
    ) {
      affected_rows
    }
  }
`;

export const TaskList = (props) => {
  const moduleID =
    props.testModule && props.testModule.test_module_id
      ? props.testModule.test_module_id
      : null;

  const { data, loading, error } = useSubscription(TASKS_SUBSCRIPTION, {
    variables: {
      moduleID: moduleID,
    },
  });
  const [deleteTask] = useMutation(DELETE_TASK);
  const [modalVisible, setModalVisible] = useState(false);
  const [copyTaskID, setCopyTaskID] = useState();
  const [selectedModule, setSelectedModule] = useState();
  const [getAllModules, { loading: modLoading, data: modData }] = useLazyQuery(
    GET_CLIENTS_WITH_YEAR_AND_PROFESSIONS
  );
  const [copyModule] = useMutation(SETCOPY);
  useEffect(() => {
    if (
      !selectedModule &&
      modData &&
      modData.Year &&
      modData.Year.length > 0 &&
      modData.Year[0].Professions &&
      modData.Year[0].Professions.length > 0 &&
      modData.Year[0].Professions[0].TestVariants &&
      modData.Year[0].Professions[0].TestVariants.length > 0 &&
      modData.Year[0].Professions[0].TestVariants[0].TestModules &&
      modData.Year[0].Professions[0].TestVariants[0].TestModules.length > 0
    ) {
      setSelectedModule(
        modData.Year[0].Professions[0].TestVariants[0].TestModules[0]
          .test_module_id
      );
    }
  }, [modData, selectedModule]);

  if (!moduleID) return "";

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :( {error.Error}</p>;
  if (!data) return <p>No data...</p>;
  if (data && data.Task && data.Task.length === 0)
    return (
      <div>
        <div className="mb-4">
          <Link
            className="bg-purple-500 hover:bg-purple-700 text-white py-2 px-4"
            to="/add-edit-task"
          >
            Neue Aufgabe anlegen
          </Link>
        </div>
        <p>Bisher keine Aufgaben angelegt</p>
      </div>
    );

  const TaskCell = (props) => <td className="px-2 py-4">{props.children}</td>;

  const taskList = data.Task.sort((a, b) =>
    collator.compare(a.name, b.name)
  ).map((task, i) => (
    <tr key={task.task_id} className={i % 2 && "bg-white"}>
      <TaskCell>
        <Link to={`/add-edit-task/${task.task_id}`}>{task.name}</Link>
      </TaskCell>
      <TaskCell>{task.TaskType.description}</TaskCell>
      <TaskCell>
        <button type="button" onClick={() => deleteTaskAction(task.task_id)}>
          Löschen
        </button>
        <div className="w-1"></div>
        <button type="button" onClick={() => copyCurrentTask(task.task_id)}>
          Kopieren
        </button>
      </TaskCell>
    </tr>
  ));

  const modList = modData
    ? modData.Year.map((year) => (
        <React.Fragment key={year.year_id}>
          {year.Professions.map((prof) => (
            <React.Fragment key={prof.profession_id}>
              {prof.TestVariants.map((variant) => (
                <React.Fragment key={variant.test_variant_id}>
                  {variant.TestModules.map((mo) => (
                    <option key={mo.test_module_id} value={mo.test_module_id}>
                      {prof.name + " - " + variant.name + " - " + mo.name}
                    </option>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))
    : null;

  const selectCopyModule = (e) => {
    setSelectedModule(e.target.value);
  };

  const copyCurrentTask = (id) => {
    setCopyTaskID(id);
    getAllModules({ variables: { yearID: props.year.year_id } });
    setModalVisible(true);
  };

  const copyTaskAction = () => {
    copyModule({
      variables: { source: copyTaskID, destination: selectedModule },
    });
    closeModal();
  };

  const closeModal = () => {
    setCopyTaskID(undefined);
    setSelectedModule(undefined);
    setModalVisible(false);
  };

  const deleteTaskAction = (taskID) => {
    deleteTask({
      variables: {
        taskID: taskID,
      },
    });
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="mb-4">
          <Link
            className="bg-purple-500 hover:bg-purple-700 text-white py-2 px-4"
            to="/add-edit-task"
          >
            Neue Aufgabe anlegen
          </Link>
        </div>
        <div className="w-full">
          <table className="w-full">
            <thead>
              <tr className="bg-white text-left text-gray-400">
                <th className="font-normal px-2 py-2">Name</th>
                <th className="font-normal px-2 py-2">Typ</th>
                <th className="font-normal px-2 py-2">Aktionen</th>
              </tr>
            </thead>
            <tbody>{taskList}</tbody>
          </table>
        </div>
      </div>
      <Rodal visible={modalVisible} onClose={closeModal}>
        {modLoading && <div>Loading...</div>}
        {!modLoading && (
          <div className="flex flex-col">
            <div className="font-bold">Task kopieren</div>
            <div className="h-4"></div>
            <div>
              <p className="text-xl">Modul wählen:</p>
            </div>
            <div className="relative mr-2">
              <select
                onChange={selectCopyModule}
                value={selectedModule}
                // defaultValue={testModule}
                className="block appearance-none w-full bg-white border border-white py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                {modList}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
            <div className="h-5"></div>
            <div className="flex flex-1 flex-row-reverse items-end">
              <button
                className="ml-4 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent inline-flex items-center"
                type="button"
                onClick={closeModal}
              >
                Abbrechen
              </button>
              <div className="w-1" />
              <button
                className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center"
                type="button"
                onClick={copyTaskAction}
              >
                Starten
              </button>
            </div>
          </div>
        )}
      </Rodal>
    </>
  );
};
