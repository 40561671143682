import React, { useState } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Redirect, useParams, Link } from "react-router-dom";
import { FormInput } from "../FormInput";
import { ContentHeadline } from "../ContentHeadline";

const ADD_CLIENT = gql`
  mutation AddClient($client: [Client_insert_input!]!) {
    insert_Client(objects: $client) {
      affected_rows
    }
  }
`;

const UPDATE_CLIENT = gql`
  mutation UpdateClient($clientID: uuid, $client: Client_set_input!) {
    update_Client(_set: $client, where: { client_id: { _eq: $clientID } }) {
      affected_rows
    }
  }
`;

const GET_CLIENT_BY_ID = gql`
  query ClientByID($clientID: uuid!) {
    Client_by_pk(client_id: $clientID) {
      client_id
      name
      portal_url
    }
  }
`;

export const AddEditClient = props => {
  const params = useParams();
  let clientID = null;
  if (params && params.clientID) {
    clientID = params.clientID;
  }

  const skip = clientID ? false : true;
  const editMode = !skip;
  const { loading, error, data, refetch } = useQuery(GET_CLIENT_BY_ID, {
    variables: {
      clientID
    },
    skip
  });
  if (!skip) {
    refetch();
  }

  const [addClient] = useMutation(ADD_CLIENT);
  const [editClient] = useMutation(UPDATE_CLIENT);

  const blankClient = {
    client_id: null,
    name: "",
    portal_url: ""
  };

  const [clientState, setClientState] = useState([{ ...blankClient }]);
  const [redirectState, setRedirectState] = useState(false);

  let currentClient = null;
  if (loading) return <p>Loading...</p>;
  if (error) console.error(error);
  if (data && data.Client_by_pk) {
    currentClient = data.Client_by_pk;
    if (!clientState.client_id && currentClient) {
      setClientState(currentClient);
    }
  }

  const handleClientChange = e => {
    setClientState({
      ...clientState,
      [e.target.name]: e.target.value
    });
  };

  const resetClient = () => {
    setClientState(blankClient);
  };

  const storeClient = e => {
    e.preventDefault();
    if (!editMode) {
      addClient({
        variables: {
          client: {
            name: clientState.name,
            portal_url: clientState.portal_url
          }
        }
      }).then(() => {
        resetClient();
        setRedirectState(true);
      });
    } else {
      editClient({
        variables: {
          clientID: clientState.client_id,
          client: {
            name: clientState.name,
            portal_url: clientState.portal_url
          }
        }
      }).then(() => {
        resetClient();
        setRedirectState(true);
      });
    }
  };

  if (redirectState) {
    return <Redirect to="/clients" />;
  }

  return (
    <div className="container mx-auto pt-8">
      <form
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={storeClient}
      >
        <div className="w-full px-3">
          <ContentHeadline
            title={
              editMode
                ? `Kunden ${clientState.name} bearbeiten`
                : "Neuen Kunden anlegen"
            }
          ></ContentHeadline>
          <div className="mb-8">
            <FormInput
              label="Name"
              inputType="text"
              id="name"
              value={clientState.name}
              onChangeEvent={handleClientChange}
              placeholder="Bitte einen Namen für den Kunden eingeben"
              required={true}
            />
          </div>
          <div className="mb-8">
            <FormInput
              label="Portal-URL"
              inputType="text"
              id="portal_url"
              value={clientState.portal_url}
              onChangeEvent={handleClientChange}
              placeholder="Die Portal URL ist der Basis-Link zu den azubi@min Webservices"
              required={true}
            />
          </div>
          <div className="flex justify-end">
            <Link
              className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center mr-4"
              to="/clients"
            >
              Abbrechen
            </Link>
            <button
              className="bg-purple-500 hover:bg-purple-700 text-white py-2 px-4"
              type="submit"
            >
              {editMode ? "Kunden bearbeiten" : "Kunden hinzufügen"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
