import React from "react";
import { useKeycloak } from "@react-keycloak/web";

export const AuthButton = () => {
  const { keycloak } = useKeycloak();
  return (
    !!keycloak.authenticated && (
      <button
        onClick={() => {
          keycloak.logout();
        }}
        className="py-2 px-4 rounded inline-flex items-center text-white"
      >
        <svg
          className="fill-current w-4 h-4 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path
            fillRule="evenodd"
            d="M4.16 4.16l1.42 1.42A6.99 6.99 0 0 0 10 18a7 7 0 0 0 4.42-12.42l1.42-1.42a9 9 0 1 1-11.69 0zM9 0h2v8H9V0z"
          />
        </svg>
        Abmelden
      </button>
    )
  );
};
