import React from "react";
import { useSubscription, useMutation } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { ContentHeadline } from "../ContentHeadline";
import * as moment from "moment";

const EXPORT_SUBSCRIPTION = gql`
  subscription Exports {
    Export(order_by: { created_at: desc }) {
      export_id
      created_at
      finished_at
      resultMessage
      Client {
        name
      }
      Year {
        description
      }
    }
  }
`;

const ADD_EXPORT = gql`
  mutation AddExport($export: [Export_insert_input!]!) {
    insert_Export(objects: $export) {
      affected_rows
    }
  }
`;

export const ExportHistory = ({ client, year }) => {
  const { data, loading, error } = useSubscription(EXPORT_SUBSCRIPTION);
  const [addExport] = useMutation(ADD_EXPORT);

  const startExport = (e) => {
    e.preventDefault();
    addExport({
      variables: {
        export: {
          client_id: client.client_id,
          year_id: year.year_id,
          year_portal_id: year.portal_id,
        },
      },
    })
      .then((r) => {
        console.log("export send", r);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :( {error.Error}</p>;
  if (!data) return <p>No data...</p>;
  if (data && data.Export && data.Export.length === 0) {
    return (
      <div>
        <button
          className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center"
          type="button"
          onClick={startExport}
        >
          <svg
            className="fill-current w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
          </svg>
          Export ausführen
        </button>
        <p>Bisher keine Exporte durchgeführt</p>
      </div>
    );
  }

  const TaskCell = (props) => <td className="px-2 py-4">{props.children}</td>;

  const getDuration = (start, end) => {
    const startM = moment(start);
    const endM = moment(end);
    let duration = moment.duration(endM.diff(startM)).milliseconds();
    if (duration < 0) {
      duration = duration * -1;
    }
    return duration + " ms";
  };

  const getStatusIcon = (resultMessage) => {
    console.log(resultMessage);

    if (resultMessage === "OK") {
      // OK
      return (
        <div className="text-green-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            className="fill-current"
          >
            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
          </svg>
        </div>
      );
    }

    if (resultMessage && resultMessage.length > 0) {
      // Error
      return (
        <div className="text-red-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            className="fill-current"
          >
            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
          </svg>
        </div>
      );
    }

    if (!resultMessage) {
      // Hour glass
      return (
        <div className="text-gray-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            className="fill-current"
          >
            <path d="M3 18a7 7 0 0 1 4-6.33V8.33A7 7 0 0 1 3 2H1V0h18v2h-2a7 7 0 0 1-4 6.33v3.34A7 7 0 0 1 17 18h2v2H1v-2h2zM5 2a5 5 0 0 0 4 4.9V10h2V6.9A5 5 0 0 0 15 2H5z" />
          </svg>
        </div>
      );
    }
  };

  const exportList = data.Export.map((exp, i) => (
    <tr key={exp.export_id} className={i % 2 && "bg-white"}>
      <TaskCell>
        <div className="w-4 h-4">{getStatusIcon(exp.resultMessage)}</div>
      </TaskCell>
      <TaskCell>
        {moment(exp.created_at).format("DD.MM.YYYY, HH:mm:ss")}
      </TaskCell>
      <TaskCell>
        {exp.finished_at
          ? getDuration(exp.created_at, exp.finished_at)
          : "Export läuft..."}
      </TaskCell>
      <TaskCell>{exp.Client.name}</TaskCell>
      <TaskCell>{exp.Year.description}</TaskCell>
    </tr>
  ));

  return (
    <div className="m-4 w-full">
      <ContentHeadline title="Export Historie"></ContentHeadline>
      <button
        className="bg-transparent hover:bg-purple-500 text-purple-700 font-semibold hover:text-white py-2 px-4 border border-purple-500 hover:border-transparent inline-flex items-center"
        type="button"
        onClick={startExport}
      >
        <svg
          className="fill-current w-4 h-4 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
        </svg>
        Export ausführen
      </button>
      <hr className="my-4 border-gray-500" />
      <div className="w-full">
        <table className="w-full">
          <thead>
            <tr className="bg-white text-left text-gray-400">
              <th className="font-normal px-2 py-2">Status</th>
              <th className="font-normal px-2 py-2">Export ausgeführt am</th>
              <th className="font-normal px-2 py-2">Übtragungsdauer</th>
              <th className="font-normal px-2 py-2">Kunde</th>
              <th className="font-normal px-2 py-2">Jahr</th>
            </tr>
          </thead>
          <tbody>{exportList}</tbody>
        </table>
      </div>
    </div>
  );
};
