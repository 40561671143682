import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";

export const TableHead = ({ fields }) => {
  const head = (
    <thead>
      <tr className="bg-white text-left text-gray-400">
        {fields.map((field, i) => {
          switch (field) {
            case "label":
              return (
                <th
                  className="uppercase tracking-wide text-gray-700 text-xs font-bold"
                  key={`field-head-${i}`}
                >
                  Label
                </th>
              );
            case "text":
              return (
                <th
                  className="uppercase tracking-wide text-gray-700 text-xs font-bold"
                  key={`field-head-${i}`}
                >
                  Text
                </th>
              );
            case "points":
              return (
                <th
                  className="uppercase tracking-wide text-gray-700 text-xs font-bold"
                  key={`field-head-${i}`}
                >
                  Punkte
                </th>
              );
            case "image":
              return (
                <th
                  className="uppercase tracking-wide text-gray-700 text-xs font-bold"
                  key={`field-head-${i}`}
                >
                  Bild
                </th>
              );
            default:
              return (
                <th
                  className="uppercase tracking-wide text-gray-700 text-xs font-bold"
                  key={`field-head-${i}`}
                >
                  {field}
                </th>
              );
          }
        })}
        <th className="uppercase tracking-wide text-gray-700 text-xs font-bold">
          Aktionen
        </th>
      </tr>
    </thead>
  );
  return head;
};

export const AnswerInputRow = ({
  idx,
  answerState,
  handleAnswerChange,
  fields,
  handleDelete
}) => {
  const answerId = `answer-${idx}`;
  const bulletId = `bullet-${idx}`;
  const answerTextId = `text-${idx}`;
  const pointsId = `points-${idx}`;
  if (!fields) {
    fields = [];
  }

  const inFields = fieldName => {
    if (fields.indexOf(fieldName) !== -1) {
      return true;
    }
    return false;
  };

  // File upload setup
  const onDropAccepted = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach(file => {
        // const newanswerState = Object({}, answerState[idx]);
        // newanswerState.image = file;
        handleAnswerChange({ image: file, idx: idx });
        // const newTaskState = { ...taskState, image: file };
        // setTaskState(newTaskState);
        // taskChanged({ ...newTaskState, answerState });
      });
    },
    [handleAnswerChange, idx]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png", // Allow only images
    maxSize: 2000000, // 2MB
    multiple: false, // allow only one file
    onDropAccepted
  });

  const acceptedFilesItems = acceptedFiles.map(file =>
    file.path ? (
      <li key={file.path}>{file.path}</li>
    ) : (
      <li key={file.name}>{file.name}</li>
    )
  );

  return (
    <tr key={answerId} className="bg-white">
      {inFields("bullet") && (
        <td className="px-2 py-2">
          {/* <label htmlFor={bulletId}>{`Bullet #${idx + 1}`}</label> */}
          <input
            type="text"
            name={bulletId}
            data-idx={idx}
            id={bulletId}
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            data-name="bullet"
            value={answerState[idx].bullet}
            onChange={handleAnswerChange}
            disabled={true}
          />
        </td>
      )}
      {inFields("label") && (
        <td className="px-2 py-2">
          {/* <label htmlFor={answerTextId}>Label</label> */}
          <input
            type="text"
            name={answerTextId}
            data-idx={idx}
            id={answerTextId}
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            data-name="label"
            value={answerState[idx].label}
            onChange={handleAnswerChange}
          />
        </td>
      )}
      {inFields("text") && (
        <td className="px-2 py-2">
          {/* <label htmlFor={answerTextId}>Text</label> */}
          <input
            type="text"
            name={answerTextId}
            data-idx={idx}
            id={answerTextId}
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            data-name="text"
            value={answerState[idx].text}
            onChange={handleAnswerChange}
          />
        </td>
      )}
      {inFields("image") && (
        <td className="px-2 py-2 flex items-start">
          <div className="grid grid-cols-3 gap-2">
            <div
              {...getRootProps({
                className:
                  "dropzone justify-center text-gray-500 bg-gray-200 p-4 border-gray-200 rounded border-solid border col-span-2"
              })}
            >
              <input {...getInputProps()} />
              <p>
                Dateien hier her ziehen, oder anklicken um eine Datei zu
                selektieren
              </p>
            </div>
            {acceptedFilesItems.length > 0 && (
              <aside className="flex col-span-1">
                <ul>{acceptedFilesItems}</ul>
              </aside>
            )}
            {answerState[idx].File && (
              <aside className="flex col-span-1">
                <span>{answerState[idx].File.filename}</span>
                {/* <ul>{answerState[idx].File}</ul> */}
              </aside>
            )}
          </div>
        </td>
      )}
      {inFields("comma") && (
        <td className="px-2 py-2">
          {/* <label htmlFor={answerTextId}>Komma (ja/nein)</label> */}
          <input
            type="checkbox"
            name={answerTextId}
            data-idx={idx}
            id={answerTextId}
            className="mr-2 leading-tight"
            data-name="comma"
            checked={answerState[idx].comma}
            onChange={handleAnswerChange}
          />
        </td>
      )}

      {inFields("word_points") && (
        <td className="px-2 py-2">
          {/* <label htmlFor={answerTextId}>Punkte (Wort)</label> */}
          <input
            type="number"
            name={answerTextId}
            data-idx={idx}
            id={answerTextId}
            className="max-w-xss appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            data-name="word_points"
            value={answerState[idx].word_points}
            onChange={handleAnswerChange}
          />
        </td>
      )}
      {inFields("comma_points") && (
        <td className="px-2 py-2">
          {/* <label htmlFor={answerTextId}>Punkte (Komma)</label> */}
          <input
            type="number"
            name={answerTextId}
            data-idx={idx}
            id={answerTextId}
            className="max-w-xss appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            data-name="comma_points"
            value={answerState[idx].comma_points}
            onChange={handleAnswerChange}
          />
        </td>
      )}
      {inFields("sample") && (
        <td className="px-2 py-2">
          {/* <label htmlFor={answerTextId}>Beispiel (Ja/Nein)</label> */}
          <input
            type="checkbox"
            name={answerTextId}
            data-idx={idx}
            id={answerTextId}
            className="mr-2 leading-tight"
            data-name="sample"
            checked={answerState[idx].sample}
            onChange={handleAnswerChange}
          />
        </td>
      )}
      {inFields("points") && (
        <td className="px-2 py-2">
          {/* <label htmlFor={pointsId}>Points</label> */}
          <input
            type="number"
            name={pointsId}
            data-idx={idx}
            id={pointsId}
            className="max-w-xss appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            data-name="points"
            value={answerState[idx].points}
            onChange={handleAnswerChange}
          />
        </td>
      )}
      <td className="px-2 py-2">
        <button
          className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent inline-flex items-center"
          type="button"
          onClick={() => handleDelete(idx)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            className="fill-current w-4 h-4"
          >
            <path d="M6 2l2-2h4l2 2h4v2H2V2h4zM3 6h14l-1 14H4L3 6zm5 2v10h1V8H8zm3 0v10h1V8h-1z" />
          </svg>
        </button>
      </td>
    </tr>
  );
};
AnswerInputRow.propTypes = {
  idx: PropTypes.number,
  answerState: PropTypes.array,
  handleAnswerChange: PropTypes.func,
  fields: PropTypes.arrayOf(PropTypes.string)
};
